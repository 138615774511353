import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { CancelInfo, UtilizadorService, ViagemCompras } from '../slidein_api';
import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card';
import { MessageContext } from '../layout/context/MessageContext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import dayjs from 'dayjs';
import * as Yup from 'yup';
import * as IBAN from 'ibantools';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { getFormErrorMessage, isFormFieldValid } from '../helpers';
import { Dialog } from 'primereact/dialog'; // For <ConfirmDialog /> component

type ReturnInfo = {
  iban: string;
  id: number;
  titular: string;
};

Yup.addMethod(Yup.string, 'ibanVal', function (errorMessage) {
  return this.test(`iban_val`, errorMessage, function (value) {
    const { path, createError } = this;
    const { valid } = IBAN.validateIBAN(value.replaceAll(' ', ''));
    return valid || createError({ path, message: errorMessage });
  });
});

dayjs.extend(utc);
dayjs.extend(timezone);
// TODO dismiss messages
export function ViagemCancelar() {
  const { viagemID } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [cancelInfo, setCancelInfo] = useState<CancelInfo>();

  const [viagem, setViagem] = useState<ViagemCompras>();
  const { clearAll } = useContext(MessageContext);
  const [valorRee, setValorRee] = useState(0);

  const [cancelModal, setCancelModal] = useState(false);

  const valSchema = Yup.object({
    iban: Yup.string().required('Obrigatório').ibanVal('Inválido'),
    titular: Yup.string()
      .required('Obrigatório')
      .min(5, 'Nome conforme associado a conta correspondente'),
  });

  const [theSchema, setTheSchema] = useState(valSchema);

  const cancelForm = useFormik<ReturnInfo>({
    initialValues: {
      id: parseInt(viagemID),
      iban: '',
      titular: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: theSchema,
    onSubmit: (data) => {
      console.log(data);
      setLoading(true);
      UtilizadorService.utilizadorComprarCvdvRetrieve({
        id: parseInt(viagemID),
        iban: cancelForm.values.iban,
        titular: cancelForm.values.titular,
      }).then(() => {
        navigate('/', { replace: true });
        window.location.reload();
        setLoading(false);
      });
    },
  });

  useEffect(() => {
    clearAll();
    UtilizadorService.utilizadorComprarRetrieve({ id: parseInt(viagemID) }).then(
      (viagem: ViagemCompras) => {
        setViagem(viagem);
        UtilizadorService.utilizadorCancelarRetrieve({ id: viagemID })
          .then((info: CancelInfo) => {
            setCancelInfo(info);
            setValorRee(viagem.valor_pago - info.total_cancel_cost);
            if (!(viagem.valor_pago - info.total_cancel_cost != 0)) {
              setTheSchema(undefined);
            }
            setLoading(false);
          })
          .catch(() => {
            navigate('/', { replace: true });
            window.location.reload();
          });
      },

      () => {
        setLoading(false);
        navigate('/', { replace: true });
        window.location.reload();
      }
    );
  }, []);

  const transRet = (cancel_info) => {
    if (cancel_info.refund_mode === 'Sem Reembolso') {
      return 'Não reembolsável.';
    }
    if (cancel_info.refund_mode === 'Reembolso Total') {
      return 'Reembolsável.';
    }
    return `Penalidade de Cancelamento ${cancelInfo.rule.percentage}%.`;
  };

  const footer = () => {
    return (
      <table>
        <tbody>
          <tr>
            <td align={'right'}>Total não reembolsável:</td>
            <td className={'text-xl pl-2'}>{cancelInfo.total_cancel_cost}€</td>
          </tr>
          <tr>
            <td align={'right'}>Total Pago:</td>
            <td className={'text-xl pl-2'}>{viagem.valor_pago}€</td>
          </tr>
          <tr style={{ borderTop: '1px solid gray' }}>
            <td
              className={'text-xl'}
              align={'right'}>
              Valor Reembolso:
            </td>
            <td
              className={'text-xl pl-2'}
              style={{ borderTop: '1px solid gray' }}>
              {viagem.valor_pago - cancelInfo.total_cancel_cost}€
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const header = () => {
    return (
      <table>
        <tbody>
          <tr>
            <td>Data de cancelamento:</td>
            <td>{cancelInfo.cancel_date}</td>
          </tr>
          <tr>
            <td>Data Inicio Viagem</td>
            <td>{cancelInfo.campanha.data_inicio_viagem}</td>
          </tr>
          <tr>
            <td>Dias:</td>
            <td>{cancelInfo.days_until_trip}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  if (loading) {
    return <div>LOADING</div>;
  }
  return (
    <div className={'flex flex-column align-items-center'}>
      <Dialog
        header='Confirmar cancelamento'
        visible={cancelModal}
        className={'sm:w-100 w-50'}
        onHide={() => setCancelModal(false)}>
        <>
          <h4>Tem a certeza que deseja cancelar a viagem?</h4>
          {valorRee != 0 && (
            <form
              onSubmit={cancelForm.handleSubmit}
              className={'w-100'}>
              <h5>Indique a seguintes informações bancarias, para se proceder ao reembolso.</h5>
              <div className='flex flex-column gap-2'>
                <div className='flex flex-column gap-2'>
                  <label
                    htmlFor='titular'
                    className={classNames({
                      'p-error': isFormFieldValid<ReturnInfo>('titular', cancelForm),
                    })}>
                    Titular da conta*
                  </label>
                  <InputText
                    id='titular'
                    name='titular'
                    value={cancelForm.values.titular || ''}
                    onChange={cancelForm.handleChange}
                    className={classNames({
                      'p-invalid': isFormFieldValid<ReturnInfo>('titular', cancelForm),
                    })}
                  />
                  {getFormErrorMessage<ReturnInfo>('titular', cancelForm)}
                </div>

                <div className='flex flex-column gap-2'>
                  <label
                    htmlFor='titular'
                    className={classNames({
                      'p-error': isFormFieldValid<ReturnInfo>('iban', cancelForm),
                    })}>
                    IBAN*
                  </label>
                  <InputText
                    id='iban'
                    name='iban'
                    value={cancelForm.values.iban || ''}
                    onChange={cancelForm.handleChange}
                    className={classNames({
                      'p-invalid': isFormFieldValid<ReturnInfo>('iban', cancelForm),
                    })}
                  />

                  {getFormErrorMessage<ReturnInfo>('iban', cancelForm)}
                </div>
              </div>
              <div className={'flex flex-row justify-content-between flex-grow-1 py-5'}>
                <Button
                  icon='pi pi-arrow-left'
                  outlined
                  type={'button'}
                  onClick={() => navigate('/aminha_viagem/' + viagem.id)}
                  severity={'secondary'}>
                  Voltar
                </Button>

                <Button
                  iconPos='right'
                  icon='pi pi-times'
                  outlined
                  type={'submit'}
                  raised
                  severity={'warning'}>
                  Cancelar Viagem
                </Button>
              </div>
            </form>
          )}
        </>
      </Dialog>
      <h3>Cancelamento viagem:</h3>
      <h4>{cancelInfo.campanha.nome}</h4>
      <Card
        title='Politica de Cancelamento:'
        className={'w-11 sm:w-7'}>
        <p className='m-0 text-lg line-height-2'>{cancelInfo.policy_text}</p>
      </Card>
      <Divider className={'w-11 sm:w-7'} />
      <h3>Penalidade de cancelamento:</h3>

      <DataTable
        size={'small'}
        header={header}
        footer={footer}
        className={'w-11 sm:w-7'}
        responsiveLayout='stack'
        value={cancelInfo.products}>
        <Column
          style={{ backgroundColor: null }}
          field='name'
          header='Produto'></Column>
        <Column
          body={(pop) => {
            return `${pop.value}€`;
          }}
          header='Preço'></Column>
        <Column
          body={(pop) => {
            return `${pop.cancel_cost}€  (${transRet(pop)})`;
          }}
          header='Valor não reembonsável'></Column>
      </DataTable>

      <Divider className={'w-11 sm:w-7'} />
      <div className={'flex flex-row justify-content-around flex-grow-1 w-11 sm:w-7 py-5'}>
        <Button
          icon='pi pi-arrow-left'
          outlined
          onClick={() => navigate('/aminha_viagem/' + viagem.id)}
          severity={'secondary'}>
          Voltar
        </Button>

        <Button
          iconPos='right'
          icon='pi pi-times'
          outlined
          raised
          onClick={() => {
            if (!dayjs.utc().isSame(dayjs(cancelInfo.cancel_date).utc(), 'day')) {
              window.location.reload();
            }
            setCancelModal(true);
          }}
          severity={'warning'}>
          Cancelar Viagem
        </Button>
      </div>
    </div>
  );
}
