import React, { useContext, useState } from 'react';
import axios from 'axios';
import { AuthService, Cliente } from '../slidein_api';
import { MessageContext } from '../layout/context/MessageContext';
import { useNavigate } from 'react-router-dom';

type MyTokenObtainPair = {
  access?: string;
  refresh?: string;
};

type AuthContextProps = {
  isAuthenticated: boolean;
  authLoading: boolean;
  token: MyTokenObtainPair;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
  user: Cliente;
  setUser: (user: Cliente) => void;
};

const AuthContext = React.createContext<Partial<AuthContextProps>>({});

export const AuthProvider = (props: { children }): React.ReactElement => {
  const [authLoading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { msgWarn } = useContext(MessageContext);
  const navigate = useNavigate();

  const [user, setUser] = useState<Cliente>(undefined);

  const [token, setToken] = useState<MyTokenObtainPair | null>(() => {
    const stToken = JSON.parse(localStorage.getItem('kaput'));
    if (stToken) {
      setIsAuthenticated(true);
      setLoading(false);
      return stToken;
    } else {
      setIsAuthenticated(false);
      setLoading(false);
      return null;
    }
  });

  const login = async (email: string, password: string): Promise<boolean> => {
    setLoading(true);
    return AuthService.authLoginCreate({
      requestBody: { email: email, password: password },
    }).then(
      (data) => {
        localStorage.setItem('kaput', JSON.stringify(data));
        setToken(data);
        setIsAuthenticated(true);
        setLoading(false);
        return true;
      },
      (reason) => {
        setIsAuthenticated(false);
        localStorage.removeItem('kaput');
        setToken(null);
        setLoading(false);
        return Promise.reject(reason);
      }
    );
  };

  const logout = (): void => {
    setIsAuthenticated(false);
    localStorage.removeItem('kaput');
    setToken(null);
    setLoading(false);
  };

  const value = {
    isAuthenticated,
    authLoading,
    token,
    login,
    logout,
    user,
    setUser,
  };

  interface QueueItem {
    resolve: (value?: string | PromiseLike<string> | undefined) => void;
    reject: (reason?) => void;
  }

  let isRefreshing = false;

  // Store requests that waiting for refresh token
  let queue: QueueItem[] = [];

  function handleQueue(err: Error | null, token = '') {
    queue.forEach((prom) => {
      if (err) {
        prom.reject(err);
      } else {
        prom.resolve(token);
      }
    });
    queue = [];
  }

  const refreshTokenUrl = '/auth/refresh/';

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.code === 'ERR_NETWORK') {
        msgWarn(
          'A página encontra-se em manutenção, pedimos desculpa pelo incómodo, por favor tente mais tarde, obrigado.',
          true
        );
        navigate('/');
        logout();
        return Promise.reject(error);
      }
      if (error.response?.status !== 401 || !isAuthenticated) {
        navigate('/');
        return Promise.reject(error);
      }

      const originalRequest = error.config;

      if (originalRequest.url.endsWith(refreshTokenUrl) || !isAuthenticated) {
        logout();
        return Promise.reject(error);
      }

      // There are no request trying to get the refresh token
      if (!isRefreshing && !originalRequest._retry) {
        originalRequest._retry = true;

        isRefreshing = true;

        return new Promise((resolve, reject) => {
          AuthService.authRefreshCreate({
            requestBody: { refresh: token.refresh },
          })
            .then((res) => {
              const ptoken = { ...token };
              ptoken.access = res.access;
              localStorage.setItem('kaput', JSON.stringify(ptoken));
              setToken(ptoken);

              originalRequest.headers.Authorization = 'Bearer ' + res.access;
              resolve(axios(originalRequest));
              handleQueue(null, res.access);
            })
            .catch((err) => {
              logout();
              handleQueue(err);
              // Handle your logic when get token failed
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }

      // There are a request trying to get the refresh token
      if (isRefreshing) {
        return new Promise<string>((resolve, reject) => {
          queue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return err;
          });
      }
      return Promise.reject(error);
    }
  );

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
