import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import {
  Actividade,
  CompraActividade,
  CompraDesconto,
  CompraEstadoEnum,
  CompraOutro,
  CompraPacote,
  CompraSeguro,
  CompraSuplemento,
  DocumentosCampanha,
  EstadoPagamentoEnum,
  GrupoAderirInfo,
  Pacote,
  RefsMultibanco,
  Seguro,
  Suplemento,
  Transacao,
  UtilizadorService,
  ViagemCompras,
} from '../slidein_api';
import FileSaver from 'file-saver';
import { Card } from 'primereact/card';
import { ListBox } from 'primereact/listbox';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import dayjs from 'dayjs';
import { PrimeIcons } from 'primereact/api';
import FullPageLoader from '../layout/FullPageLoader';
import { calculate_prestas, CalculatedPrestacao, MBLOGO } from '../helpers';
import { MessageContext } from '../layout/context/MessageContext';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';

export function ViagemFechadaView() {
  const { viagemID } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [viagem, setViagem] = useState<ViagemCompras>();
  const navigate = useNavigate();
  const [grupoInfo, setGrupoInfo] = useState<GrupoAderirInfo>();

  const [cpacote, setCPacote] = useState<CompraPacote>(undefined);
  const [cseguro, setCSeguro] = useState<CompraSeguro>(undefined);
  const [cactividades, setCActividades] = useState<CompraActividade[]>([]);
  const [csuplementos, setCSuplementos] = useState<CompraSuplemento[]>([]);
  const [cdescontos, setCDescontos] = useState<CompraDesconto[]>([]);
  const [coutros, setCOutros] = useState<CompraOutro[]>([]);
  // const [custosOper, setCustosOper] = useState<CompraOutro[]>([]);
  const [coTotal, setcoTotal] = useState<number>(0);

  const [pacotes, setPacotes] = useState<Pacote[]>([]);
  const [seguros, setSeguros] = useState<Seguro[]>([]);
  const [actividades, setActividades] = useState<Actividade[]>([]);
  const [suplementos, setSuplementos] = useState<Suplemento[]>([]);
  const [calcPrestas, setCalcPrestas] = useState<CalculatedPrestacao[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);

  const { msgSuccess, message, msgWarn } = useContext(MessageContext);
  const [obgComprar, setObgComprar] = useState<boolean>(false);

  const [docsGerais, setDocsGerais] = useState<DocumentosCampanha[]>([]);

  const [theRef, setTheRef] = useState<RefsMultibanco>(undefined);
  useEffect(() => {
    setLoading(true);
    const oo = searchParams.get('obg');
    if (oo) {
      setObgComprar(true);
      searchParams.delete('obg');
      setSearchParams(searchParams);
    }

    UtilizadorService.utilizadorFechadaRetrieve({ id: parseInt(viagemID) }).then(
      (value: ViagemCompras) => {
        if (value.cancelada) {
          navigate('/');
          window.location.reload();
        }
        setViagem(value);
        if (!value.tem_deca_menores && value.idade_no_fim < 18) {
          message.current.replace({
            sticky: true,
            severity: 'warn',
            detail:
              'Caro Viajante, na data do início da viagem ainda és menor de idade! Deves submeter, no fundo da página, a Autorização de saída de menor de território nacional, até uma semana antes da partida.',
            closable: false,
            icon: PrimeIcons.EXCLAMATION_CIRCLE,
          });
        }

        setCPacote(value.compra_pacote);
        if (value.compra_seguro) {
          setCSeguro(value.compra_seguro);
        }
        setCActividades(value.compras_actividade);
        setCSuplementos(value.compras_suplemento);
        setCDescontos(value.compras_desconto);
        // setCustosOper(value.compras_outro.filter((o) => o.custos_de_operacao));
        setCOutros(value.compras_outro.filter((o) => !o.custos_de_operacao));

        setcoTotal(
          value.compras_outro
            .filter((o) => o.custos_de_operacao)
            .reduce((sum, value) => sum + value.valor, 0)
        );

        UtilizadorService.utilizadorGrupoInfoRetrieve({ codigo: value.grupo }).then(
          (value2) => {
            setGrupoInfo(value2);

            setDocsGerais(
              value2.campanha.documentos.filter((doc) => !doc.titulo.includes('menor'))
            );

            setPacotes([value.compra_pacote.produto_ro]);
            setSeguros([value.compra_seguro.produto_ro]);
            const acti_m = [];
            value.compras_actividade.forEach((ca) => {
              if (acti_m.find((am) => am.id === ca.produto_ro.id) === undefined) {
                acti_m.push(ca.produto_ro);
              }
            });

            setActividades(acti_m);

            const sup_m = [];
            value.compras_suplemento.forEach((ca) => {
              if (sup_m.find((am) => am.id === ca.produto_ro.id) === undefined) {
                sup_m.push(ca.produto_ro);
              }
            });

            setSuplementos(sup_m);

            setCalcPrestas(
              calculate_prestas(
                value2.prestacoes,
                value.valor_total,
                value.compra_seguro.produto_ro.valor,
                value2.valor_presta_ref_min,
                value.valor_devido,
                value.valor_custo_operacao
              )
            );

            if (
              dayjs(value2.campanha.data_fim_viagem).isAfter(dayjs(value.validade_identificacao))
            ) {
              message.current.replace({
                sticky: true,
                severity: 'warn',
                detail:
                  'Caro Viajante, o teu documento de identificação estará expirado durante a viagem, após a renovação, por favor atualiza a data de validade do teu documento.',
                closable: false,
                icon: PrimeIcons.EXCLAMATION_CIRCLE,
              });
            }

            setLoading(false);
          },
          () => {
            setLoading(false);
          }
        );
      },
      () => {
        // console.error(reason);
        setLoading(false);
        msgWarn(
          'A página encontra-se em manutenção, pedimos desculpa pelo incómodo, por favor tente mais tarde, obrigado.',
          true
        );
        navigate('/', { replace: true });
        window.location.reload();
      }
    );
  }, [reload]);

  const pacoteTemplate = (pac: Pacote) => (
    <div className={'flex flex-row flex-wrap sm:flex-nowrap gap-1 justify-content-between '}>
      <div className='flex flex-column'>
        <h3>{pac.titulo_cliente}</h3>
        <p>{pac.descricao || ''}</p>
        <div className={'flex flex-row gap-2'}>
          {pac.docs.map((doc) => (
            <Link
              onClick={() => {
                window.open(doc.file);
              }}
              className={'max-w-max'}
              key={doc.nome}
              download
              target={'_blank'}
              to={doc.file}>
              🔗 {doc.nome}
            </Link>
          ))}
        </div>
      </div>
      <div className={'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'}>
        {cpacote.estado === CompraEstadoEnum.VENDIDO && (
          <>
            <div className={'text-3xl font-medium'}>{pac.valor}€</div>
            <span className={'text-3xl text-primary white-space-nowrap pi pi-check-square '}></span>
          </>
        )}
        {cpacote.estado === CompraEstadoEnum.CANCELADO && (
          <>
            <div className={'text-3xl font-medium line-through'}>{pac.valor}€</div>
            <span className={'text-3xl text-primary white-space-nowrap text-red-500'}>
              <i className={'pi pi-times'}></i>
              Cancelado
            </span>
          </>
        )}
      </div>
    </div>
  );
  const seguroTemplate = (seg: Seguro) => (
    <div className={'flex flex-row flex-wrap sm:flex-nowrap gap-1 justify-content-between '}>
      <div className='flex flex-column'>
        <h3>{seg.titulo_cliente}</h3>
        <p>{seg.descricao || ''}</p>
        <div className={'flex flex-row gap-2'}>
          {seg.docs.map((doc) => (
            <Link
              className={'max-w-max'}
              onClick={() => {
                window.open(doc.file);
              }}
              target={'_blank'}
              download
              key={doc.nome}
              to={doc.file}>
              🔗 {doc.nome}
            </Link>
          ))}
        </div>
      </div>
      <div className={'flex flex-column gap-2 justify-content-around align-items-end ml-auto'}>
        {seg.base ||
          (cseguro.estado === CompraEstadoEnum.VENDIDO &&
            (cseguro.estado_pagamento === EstadoPagamentoEnum.PAGO_E_TRATADO ||
              cseguro.estado_pagamento === EstadoPagamentoEnum.PAGO_N_O_TRATADO) && (
              <>
                <div className={'text-3xl font-medium'}>+{seg.valor}€</div>
                <span
                  className={'text-3xl text-primary white-space-nowrap pi pi-check-square '}></span>
              </>
            ))}
        {!seg.base && cseguro.estado === CompraEstadoEnum.CANCELADO && (
          <>
            <div className={'text-3xl font-medium line-through'}>+{seg.valor}€</div>
            <span className={'text-xl white-space-nowrap text-red-500'}>
              <i className={'pi pi-times mx-2'}></i>
              Cancelado
            </span>
          </>
        )}
        {!seg.base && cseguro.estado_pagamento === EstadoPagamentoEnum.AGUARDAR_PAGAMENTO && (
          <>
            <div className={'text-3xl font-medium'}>+{seg.valor}€</div>
            <div className={'sm:text-xl font-medium text-yellow-500'}>
              <i className={'pi pi-exclamation-circle'}></i> Aguarda pagamento
            </div>
          </>
        )}
      </div>
    </div>
  );

  const actividadeTemplate = (act: Actividade) => (
    <div className={'flex flex-row flex-wrap sm:flex-nowrap gap-1 justify-content-between '}>
      <div className='flex flex-column'>
        <h3>{act.titulo_cliente}</h3>
        <p>{act.descricao || ''}</p>
        <div className={'flex flex-row gap-2'}>
          {act.docs.map((doc) => (
            <Link
              className={'max-w-max'}
              onClick={() => {
                window.open(doc.file);
              }}
              target={'_blank'}
              download
              key={doc.nome}
              to={doc.file}>
              🔗 {doc.nome}
            </Link>
          ))}
        </div>
      </div>
      {cactividades.find((e) => e.produto_ro.id === act.id) !== undefined ? (
        <div className={'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'}>
          {cactividades.find((e) => e.produto_ro.id === act.id).estado ===
            CompraEstadoEnum.VENDIDO &&
            (viagem.compras_permitidas && viagem.valor_devido >= act.valor && act.cancelavel ? (
              <div
                className={
                  'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'
                }>
                <div className={'text-3xl font-medium'}>+{act.valor}€</div>
              </div>
            ) : (
              <>
                <div className={'text-3xl font-medium'}>{act.valor}€</div>
                <span
                  className={'text-3xl text-primary white-space-nowrap pi pi-check-square'}></span>
              </>
            ))}
          {cactividades.find((e) => e.produto_ro.id === act.id).estado ===
            CompraEstadoEnum.CANCELADO && (
            <>
              <div className={'text-3xl font-medium line-through'}>{act.valor}€</div>
              <span className={'text-3xl white-space-nowrap text-red-500'}>
                <i className={'pi pi-times mx-2'}></i>
                Cancelado
              </span>
            </>
          )}
        </div>
      ) : (
        <>
          {act.inscricao_previa ? (
            <div
              className={'flex flex-column gap-2 justify-content-around align-items-end ml-auto'}>
              <div className={'text-3xl font-medium'}>+{act.valor}€</div>
              <div className={'sm:text-xl font-medium'}>{act.msg_metodo_inscricao}</div>
            </div>
          ) : act.brevemente ? (
            <div
              className={
                'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'
              }>
              <div className={'text-3xl font-medium'}>Brevemente</div>
            </div>
          ) : (
            <div
              className={
                'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'
              }>
              <div className={'text-3xl font-medium'}>+{act.valor}€</div>
            </div>
          )}
        </>
      )}
    </div>
  );
  const suplementoTemplate = (sup: Suplemento) => (
    <div className={'flex flex-row flex-wrap sm:flex-nowrap gap-1 justify-content-between '}>
      <div className='flex flex-column'>
        <h3>{sup.titulo_cliente}</h3>
        <p>{sup.descricao || ''}</p>
        <div className={'flex flex-row gap-2'}>
          {sup.docs.map((doc) => (
            <Link
              className={'max-w-max'}
              onClick={() => {
                window.open(doc.file);
              }}
              target={'_blank'}
              download
              key={doc.nome}
              to={doc.file}>
              🔗 {doc.nome}
            </Link>
          ))}
        </div>
      </div>
      {csuplementos.find((e) => e.produto_ro.id === sup.id) !== undefined ? (
        <div className={'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'}>
          {csuplementos.find((e) => e.produto_ro.id === sup.id).estado ===
            CompraEstadoEnum.VENDIDO && (
            <>
              <div className={'text-3xl font-medium'}>{sup.valor}€</div>
              <span
                className={'text-3xl text-primary white-space-nowrap pi pi-check-square'}></span>
            </>
          )}
          {csuplementos.find((e) => e.produto_ro.id === sup.id).estado ===
            CompraEstadoEnum.CANCELADO && (
            <>
              <div className={'text-3xl font-medium line-through'}>{sup.valor}€</div>
              <span className={'text-3xl white-space-nowrap text-red-500'}>
                <i className={'pi pi-times mx-2'}></i>
                Cancelado
              </span>
            </>
          )}
        </div>
      ) : (
        <>
          {sup.inscricao_previa ? (
            <div
              className={'flex flex-column gap-2 justify-content-around align-items-end ml-auto'}>
              <div className={'text-3xl font-medium'}>+{sup.valor}€</div>
              <div className={'sm:text-xl font-medium'}>{sup.msg_metodo_inscricao}</div>
            </div>
          ) : sup.brevemente ? (
            <div
              className={
                'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'
              }>
              <div className={'text-3xl font-medium'}>Brevemente</div>
            </div>
          ) : (
            <div
              className={
                'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'
              }>
              <div className={'text-3xl font-medium'}>+{sup.valor}€</div>
            </div>
          )}
        </>
      )}
    </div>
  );

  const outroTemplate = (tx: CompraOutro) => (
    <div className={'flex flex-row flex-wrap sm:flex-nowrap gap-1 justify-content-between '}>
      <div className='flex flex-column'>
        <h5>{tx.nome}</h5>
        <p>{tx.descricao || ''}</p>
      </div>
      <div className={'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'}>
        <>
          <div className={'text-3xl font-medium'}>{tx.valor}€</div>
          <span className={'text-3xl text-primary white-space-nowrap pi pi-check-square'}></span>
        </>
      </div>
    </div>
  );

  const descontoTemplate = (sup: CompraDesconto) => (
    <div className={'flex flex-row flex-wrap sm:flex-nowrap gap-1 justify-content-between '}>
      <div className='flex flex-column'>
        <h3>{sup.produto.titulo_cliente}</h3>
        <p>{sup.produto.descricao || ''}</p>
      </div>
      <div className={'flex sm:flex-column gap-2 justify-content-around align-items-end ml-auto'}>
        {sup.estado === CompraEstadoEnum.VENDIDO && (
          <>
            <div className={'text-3xl font-medium'}>-{sup.produto.valor}€</div>
            <span className={'text-3xl text-primary white-space-nowrap pi pi-check-square'}></span>
          </>
        )}
        {sup.estado === CompraEstadoEnum.CANCELADO && (
          <>
            <div className={'text-3xl font-medium line-through'}>- {sup.produto.valor}€</div>
            <span className={'text-3xl white-space-nowrap text-red-500'}>
              <i className={'pi pi-times mx-2'}></i>
              Cancelado
            </span>
          </>
        )}
      </div>
    </div>
  );

  function prestaEstado(presta: CalculatedPrestacao) {
    if (presta.total_pago <= viagem.valor_pago) {
      return <span className={'font-bold text-green-400'}>Pago</span>;
    } else if (dayjs(presta.data).add(1, 'day').isBefore(dayjs(viagem.data_hoje))) {
      return (
        <span className={'font-bold text-red-500'}>
          Atrasado
          <i className={'pi pi-exclamation-triangle'} />
        </span>
      );
    } else {
      return 'Aguardar pagamento';
    }
  }

  if (loading || !viagem || !grupoInfo) {
    return <FullPageLoader />;
  }

  function sizeCheck(file: File) {
    if (file.size >= 10000000) {
      alert('Ficheiro demasiado grande > 10 mb!');
    }
  }

  function upDeca(event: FileUploadHandlerEvent) {
    setLoading(true);
    UtilizadorService.utilizadorDecaCreate({
      formData: {
        viagem: parseInt(viagemID),
        file: event.files[0],
      },
    }).then(
      () => {
        setLoading(false);
        setReload(!reload);
        msgSuccess('Declaração submetida.', '', true);
      },
      () => {
        setLoading(false);

        setReload(!reload);
      }
    );
  }

  function show_refMul(p_valor: number, nome: string) {
    const v_refs = viagem.referencias_abertas.filter(
      (ref) => ref.valor_sem_custo_operacao == p_valor
    );
    if (v_refs.length >= 1) {
      setTheRef(v_refs.pop());
    } else {
      setLoading(true);
      UtilizadorService.utilizadorRefmulCreate({
        requestBody: { valor: p_valor, nome: nome, viagem: parseInt(viagemID) },
      }).then(
        (value) => {
          setTheRef(value);
          setLoading(false);
        },
        () => {
          message.current.replace({
            sticky: true,
            severity: 'error',
            detail:
              'Estamos com dificuldades temporárias em obter a Referência multibanco. Se o problema persistir, entre em contacto connosco. Obrigado pela compreensão.',
            closable: true,
            icon: PrimeIcons.EXCLAMATION_CIRCLE,
          });
          setReload(!reload);
        }
      );
    }
  }

  return (
    <div className={'max-w-full sm:max-w-min sm:min-w-full m-auto'}>
      <Dialog
        header='Referência Multibanco'
        visible={theRef !== undefined}
        className={'sm:w-auto w-screen'}
        onHide={() => {
          setTheRef(undefined);
          setReload(!reload);
        }}>
        <Divider></Divider>
        <div className='flex flex-row gap-p-2 ml-4 gap-5 justify-content-center flex-wrap text-xl'>
          <div className={'sm:w-10rem w-6rem'}>
            <MBLOGO />
          </div>
          <div className={'flex flex-column gap-1'}>
            <div className={'flex flex-row align-items-baseline  gap-3 flex-wrap'}>
              <div className={'flex align-items-center'}>Entidade:</div>
              <span className={'flex ml-1 monospaced text-2xl'}>
                {theRef?.entidade}
                <i
                  className={PrimeIcons.COPY + ' mx-2'}
                  onClick={() => {
                    navigator.clipboard.writeText(theRef?.entidade).then(() => {
                      msgSuccess('Referência Multibanco', 'Entidade copiada!');
                    });
                  }}></i>
              </span>
            </div>
            <div className={'flex flex-row align-items-baseline gap-3 flex-wrap'}>
              <div className={'flex align-items-center'}>Referência:</div>
              <div className={'flex monospaced  ml-1 text-2xl'}>
                {theRef?.referencia}
                <i
                  className={PrimeIcons.COPY + ' mx-2'}
                  onClick={() => {
                    navigator.clipboard.writeText(theRef?.referencia).then(() => {
                      msgSuccess('Referência Multibanco', 'Referência copiada!');
                    });
                  }}></i>
              </div>
            </div>
            <div className={'flex flex-row align-items-baseline gap-3 flex-wrap'}>
              <div className={'flex align-items-center'}>Valor:</div>
              <div
                className={
                  'flex monospaced  align-items-baseline ml-1 text-3xl gap-2'
                }>
                {theRef?.valor}€{' '}
                <span className={'font-italic text-base'}>
                  {theRef?.custo_operacao > 0
                    ? `(${theRef.valor_sem_custo_operacao}€+${theRef.custo_operacao}€*)`
                    : ''}
                </span>
              </div>
            </div>
            <div className={'flex flex-row align-items-baseline gap-3 flex-wrap'}>
              {/*<div className={'flex align-items-center'}>Validade:</div>*/}
              {/*<div className={'monospaced  ml-1'}>*/}
              {/*  {dayjs(theRef?.data_fim).format('YYYY-MM-DD')}*/}
              {/*</div>*/}
            </div>
            {theRef?.custo_operacao > 0 && (
              <div className={'vertical-align-middle'}>
                * Custo de operação.
                <Tooltip target='.toperinfo' />
                <i
                  className='toperinfo pi pi-info-circle mx-1'
                  style={{
                    fontSize: '1rem',
                    color: 'blue',
                    fontWeight: 'bolder',
                  }}
                  data-pr-tooltip='Os custos de operação referem-se a taxas do meio de pagamento. Os custos de operação não são reembolsáveis em qualquer circunstância de acordo com os termos e condições gerais da SLIDE IN.'
                  data-pr-position='bottom'
                  data-pr-event='hover'
                />
              </div>
            )}
          </div>
        </div>
      </Dialog>

      <div className='flex flex-column justify-content-center'>
        <h2>A tua viagem</h2>
      </div>
      <div className={'grid'}>
        <div className='col-12 sm:col-7'>
          <Card className={'m-auto'}>
            <h3> Pacote Viagem/Alojamento </h3>
            <ListBox
              dataKey={'id'}
              className={'produtos_style'}
              itemTemplate={pacoteTemplate}
              metaKeySelection={false}
              multiple={false}
              options={pacotes}
              onChange={() => {}}
              value={cpacote.produto_ro}
            />
            {seguros.length >= 1 && (
              <div className={'mt-4'}>
                <h3>Seguro</h3>
                <ListBox
                  dataKey={'id'}
                  className={'produtos_style'}
                  itemTemplate={seguroTemplate}
                  metaKeySelection={false}
                  multiple={false}
                  onChange={() => {}}
                  value={cseguro ? cseguro.produto_ro : undefined}
                  options={seguros}
                />
              </div>
            )}
            {grupoInfo.actividades.length >= 1 && (
              <div className={'mt-4'}>
                <h3>Opcionais</h3>
                <ListBox
                  dataKey={'id'}
                  value={cactividades.map((e) => e.produto_ro)}
                  className={'produtos_style'}
                  itemTemplate={actividadeTemplate}
                  metaKeySelection={false}
                  //   optionValue={'id'}
                  multiple={true}
                  onChange={() => {}}
                  options={actividades}
                />
              </div>
            )}
            {grupoInfo.suplementos.length >= 1 && (
              <div className={'mt-4'}>
                <h3>Suplementos</h3>
                <ListBox
                  dataKey={'id'}
                  value={csuplementos.map((e) => e.produto_ro)}
                  className={'produtos_style'}
                  itemTemplate={suplementoTemplate}
                  metaKeySelection={false}
                  //   optionValue={'id'}
                  multiple={true}
                  onChange={() => {}}
                  options={suplementos}
                />
              </div>
            )}
            {coutros.length >= 1 && (
              <div className={'mt-4'}>
                <h4>Outros</h4>
                <ListBox
                  dataKey={'id'}
                  value={coutros.map((e) => e)}
                  className={'produtos_style'}
                  itemTemplate={outroTemplate}
                  metaKeySelection={false}
                  //   optionValue={'id'}
                  multiple={true}
                  onChange={() => {}}
                  options={coutros}
                />
              </div>
            )}
            {/*{custosOper.length >= 1 && (*/}
            {/*  <div className={'mt-4'}>*/}
            {/*    <h4>Custos de operação</h4>*/}
            {/*    <ListBox*/}
            {/*      dataKey={'id'}*/}
            {/*      value={custosOper.map((e) => e)}*/}
            {/*      className={'produtos_style'}*/}
            {/*      itemTemplate={outroTemplate}*/}
            {/*      metaKeySelection={false}*/}
            {/*      //   optionValue={'id'}*/}
            {/*      multiple={true}*/}
            {/*      onChange={() => {}}*/}
            {/*      options={custosOper}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}

            {cdescontos.length >= 1 && (
              <div className={'mt-4'}>
                <h3>Descontos</h3>
                <ListBox
                  dataKey={'id'}
                  className={'produtos_style'}
                  itemTemplate={descontoTemplate}
                  metaKeySelection={false}
                  //   optionValue={'id'}
                  multiple={true}
                  onChange={() => {}}
                  options={cdescontos}
                />
              </div>
            )}
            {grupoInfo.campanha.nao_incluido != '' && (
              <Card className={'m-auto'}>
                <h3> Não Inclui :</h3>
                <p>{grupoInfo.campanha.nao_incluido}</p>
              </Card>
            )}
          </Card>
        </div>
        <div className='col-12 sm:col-5 '>
          <Card
            className={'m-auto mt-1 sticky'}
            style={{ top: '5%' }}>
            <div className={'flex flex-row gap-4'}>
              <div className={'flex flex-shrink-1'}>
                <Image
                  width={'100px'}
                  src={grupoInfo.campanha.marca.logo}
                  imageStyle={{
                    background: 'whitesmoke',
                    padding: '6px',
                    borderRadius: '5%',
                  }}
                />
              </div>
              <div className={'flex flex-column flex-grow-1'}>
                <div className={'font-italic'}>
                  <i className={PrimeIcons.PHONE + ' mx-1'} />
                  {grupoInfo.campanha.marca.telefone}
                </div>
                <div className={'font-italic'}>
                  <i className={PrimeIcons.AT + ' mx-1'} />
                  {grupoInfo.campanha.marca.email}
                </div>
              </div>
            </div>
            <Divider></Divider>
            <div className={'text-3xl'}>{grupoInfo.campanha.nome}</div>
            <div className={'text-2xl my-2'}>Destino: {grupoInfo.campanha.destino}</div>
            <div className={'text-xl my-2'}>
              A decorrer de {grupoInfo.campanha.data_inicio_viagem}&nbsp;a&nbsp;
              {grupoInfo.campanha.data_fim_viagem} ({grupoInfo.campanha.nr_noites} noites)
            </div>
            <Divider></Divider>
            <div className={'text-xl'}>{grupoInfo.instituicao}</div>
            <div className={'mt-1 text-lg'}>Código do teu grupo: {grupoInfo.codigo}</div>
            <Divider></Divider>
            {grupoInfo.contrato_viagem && (
              <>
                <div className={'flex flex-row flex-gap-1'}>
                  <div className={'text-2xl'}>Contrato Viagem:</div>
                  <Link
                    className={'m-1'}
                    target={'_blank'}
                    download
                    to={grupoInfo.contrato_viagem.file}>
                    🔗 {grupoInfo.contrato_viagem.nome}
                  </Link>
                </div>
                <Divider></Divider>
              </>
            )}
            {viagem.cliente_transporte && (
              <>
                <div className={'text-3xl'}>Transporte:&nbsp;</div>
                <div
                  className={
                    'flex flex-row gap-2 mx-1 my-1 align-items-center justify-content-center flex-wrap'
                  }>
                  <div className={'flex flex-column'}>
                    <div className={'font-bold text-4xl'}>
                      {viagem.cliente_transporte?.transporte_ro.nome}
                    </div>
                  </div>
                  <Divider
                    className={'hidden md:block'}
                    layout='vertical'></Divider>
                  <div className={'flex flex-column gap-2'}>
                    <div className={'flex flex-row gap-1  flex-wrap'}>
                      <div className={'font-bold text-xl'}>Localidade:</div>
                      <div className={'font-italic text-xl'}>
                        {viagem.cliente_transporte?.localidade}
                      </div>
                    </div>
                    <div className={'flex flex-row gap-1   flex-wrap'}>
                      <div className={'font-bold text-xl '}>Local de Partida:</div>
                      <div className={'font-italic text-xl'}>
                        {viagem.cliente_transporte?.local_partida}
                      </div>
                    </div>
                    <div className={'flex flex-row gap-1   flex-wrap'}>
                      <div className={'font-bold text-xl'}>Data e Hora:</div>
                      <div className={'font-italic text-xl'}>
                        {viagem.cliente_transporte?.hora_partida
                          ? dayjs(viagem.cliente_transporte?.hora_partida)
                              .locale('pt')
                              .format('YYYY-MM-DD HH:mm')
                          : '-'}
                      </div>
                    </div>
                  </div>
                </div>

                <Divider></Divider>
              </>
            )}
            {viagem.cliente_quarto && (
              <>
                <div className={'text-3xl my-2'}>Quarto:&nbsp;</div>

                <div
                  className={
                    'flex flex-column gap-2 mx-1 my-1 align-items-center justify-content-center flex-wrap'
                  }>
                  <div className={''}>{viagem.cliente_quarto.split(', Quarto:')[0]}</div>
                  <div className={'font-bold text-2xl'}>
                    Quarto:
                    {viagem.cliente_quarto.split(', Quarto:')[1]}
                  </div>
                </div>

                <Divider></Divider>
              </>
            )}
            <div className={'text-2xl'}>Preço Total: {viagem.valor_total - coTotal}€</div>
            <Divider></Divider>
            {(grupoInfo.campanha.pedir_tshirt ||
              grupoInfo.campanha.pedir_peso ||
              grupoInfo.campanha.pedir_altura ||
              grupoInfo.campanha.pedir_calcado) && (
              <p className={'text-2xl font-bold'}>Informações Logísticas/Promocionais</p>
            )}
            <div className={'flex flex-row gap-4 flex-warp'}>
              {grupoInfo.campanha.pedir_tshirt && (
                <>
                  <div className='flex flex-row gap-2 justify-content-start align-content-center align-items-center'>
                    <div className={'text-xl '}>Tamanho T-shirt:</div>
                    <div className={'text-xl font-bold'}>
                      {(viagem.tamanho_tshirt || '--').toUpperCase()}
                    </div>
                  </div>
                </>
              )}
              {grupoInfo.campanha.pedir_peso && (
                <>
                  <div className='flex flex-row gap-2 justify-content-start align-content-center align-items-center'>
                    <div className={'text-xl '}>Peso:</div>
                    <div className={'text-xl font-bold'}>{viagem.peso || '--'}Kg</div>
                  </div>
                </>
              )}
              {grupoInfo.campanha.pedir_altura && (
                <>
                  <div className='flex flex-row gap-2 justify-content-start align-content-center align-items-center'>
                    <div className={'text-xl '}>Altura:</div>
                    <div className={'text-xl font-bold'}>{viagem.altura || '--'} metros</div>
                  </div>
                </>
              )}
              {grupoInfo.campanha.pedir_calcado && (
                <>
                  <div className='flex flex-row gap-2 justify-content-start align-content-center align-items-center'>
                    <div className={'text-xl '}>Número Calçado:</div>
                    <div className={'text-xl font-bold'}>{viagem.calcado || '--'}</div>
                  </div>
                </>
              )}
            </div>
            {(grupoInfo.campanha.pedir_tshirt ||
              grupoInfo.campanha.pedir_peso ||
              grupoInfo.campanha.pedir_altura ||
              grupoInfo.campanha.pedir_calcado) && <Divider></Divider>}

            <h2>Pagamentos da viagem</h2>
            {grupoInfo.campanha.mostrar_prestas_clientes && (
              <>
                <p className={'text-xl font-bold'}>Plano de Prestações</p>
                <DataTable
                  dataKey={'data'}
                  id={'prestas_table'}
                  loading={loading}
                  lazy={true}
                  value={calcPrestas}
                  size={'small'}
                  // rowClassName={(presta: CalculatedPrestacao) => {
                  //   if (viagem.valor_devido === 0) {
                  //     return '';
                  //   }
                  //   if (
                  //     viagem.valor_pago < presta.total_pago &&
                  //     dayjs(presta.data).add(1, 'day').isBefore(dayjs(viagem.data_hoje))
                  //   ) {
                  //     return 'bg-red-500 text-black-alpha-90';
                  //   }
                  //   return '';
                  // }}
                  showGridlines
                  stripedRows>
                  <Column
                    header={'Data Limite'}
                    body={(presta: CalculatedPrestacao) => {
                      return dayjs(presta.data).locale('pt').format('YYYY-MM-DD');
                    }}
                  />
                  <Column
                    header={'Valor da Prestação'}
                    // field={'valor_str'}
                    body={(presta: CalculatedPrestacao) => {
                      if (presta.total_pago <= viagem.valor_pago || !presta.f_pagar) {
                        return presta.valor_str;
                      }
                      if (presta.valor > viagem.valor_devido) {
                        return (
                          <div>
                            <s>{presta.valor}€</s> {viagem.valor_devido}€ Restante
                          </div>
                        );
                      } else {
                        return presta.valor_str;
                      }
                    }}
                  />
                  <Column
                    align={'center'}
                    body={prestaEstado}
                  />
                  <Column
                    align={'center'}
                    header={'Ref. Multibanco'}
                    body={(presta: CalculatedPrestacao) => {
                      if (presta.total_pago <= viagem.valor_pago || !presta.f_pagar) {
                        return <b>--</b>;
                      } else {
                        const the_value = Math.min(presta.valor, viagem.valor_devido);

                        return (
                          <Button
                            className='border-blue-600'
                            outlined={true}
                            size='small'
                            onClick={() => {
                              setTheRef(undefined);
                              show_refMul(the_value, `prestação (${presta.data})`);
                            }}>
                            <div className={'w-1rem'}>
                              <MBLOGO />
                            </div>
                            <span className={'ml-1 font-bold text-blue-500'}>
                              {' '}
                              Pagar {the_value}€{' '}
                            </span>
                          </Button>
                        );
                      }
                    }}></Column>
                </DataTable>
                {viagem.valor_devido > 0 && (
                  <>
                    <Divider></Divider>
                    <div
                      className={'flex flex-row my-2 gap-3 justify-content-end align-items-center'}>
                      <div className={'font-semibold text-lg '}>
                        Pagar Total / Restante({viagem.valor_devido + '€'}):
                      </div>
                      <Button
                        className='border-blue-600'
                        outlined={true}
                        onClick={() => {
                          setTheRef(undefined);
                          show_refMul(viagem.valor_devido, `total/restante`);
                        }}>
                        <div className={'w-1rem'}>
                          <MBLOGO />
                        </div>
                        <span className={'ml-2 font-bold text-blue-500'}>
                          {' '}
                          Pagar {viagem.valor_devido}€{' '}
                        </span>
                      </Button>
                    </div>
                    <i className={'mt-1'}>
                      <b>Nota:</b>O não pagamento do preço total da viagem nas condições
                      mencionadas, implicará o cancelamento da viagem, sendo aplicadas as condições
                      previstas nas Condições Gerais de Venda na clausula seguinte- “Desistência do
                      Cliente”, à data do incumprimento do plano.
                    </i>
                  </>
                )}
              </>
            )}
            <Divider></Divider>
            <h2>Pagamentos Efetuados:</h2>
            <DataTable
              dataKey={'creation_date'}
              id={'pag_table'}
              loading={loading}
              lazy={true}
              value={viagem.pagamentos_confirmados}
              size={'small'}
              footer={() => (
                <div className={'flex flex-column justify-content-end'}>
                  <div className={'ml-auto text-xl'}>
                    Pagamento total realizado: {viagem.valor_pago - coTotal} €
                  </div>
                  {coTotal > 0 && (
                    <div className={'ml-auto text-lg'}>Custos de Operação : {coTotal} €</div>
                  )}
                  <Divider></Divider>
                  <div className={'ml-auto text-xl'}>
                    Preço Total: {viagem.valor_total - coTotal} €
                  </div>
                  <div className={'ml-auto text-xl font-semibold'}>
                    Por Pagar: {viagem.valor_devido} €
                  </div>
                </div>
              )}
              showGridlines
              stripedRows>
              <Column
                header={'Data'}
                body={(pag: Transacao) => {
                  return dayjs(pag.creation_date).locale('pt').format('YYYY-MM-DD HH:mm');
                }}
              />
              <Column
                header={'Valor'}
                body={(pag: Transacao) => {
                  return (
                    (pag.tipo === 'Devolução' ? '-' : '') +
                    pag.valor_sem_custo_de_operacao.toString() +
                    '€'
                  );
                }}
              />

              <Column
                header={'Tipo'}
                body={(pag: Transacao) => {
                  return pag.tipo;
                }}
              />

              <Column
                header={
                  <div>
                    Custo de
                    <br />
                    Operação <Tooltip target='.paginfo' />
                    <i
                      className='paginfo pi pi-info-circle mx-1'
                      style={{
                        fontSize: '1rem',
                        color: 'blue',
                        fontWeight: 'bolder',
                      }}
                      data-pr-tooltip='Os custos de operação referem-se a taxas do meio de pagamento. Os custos de operação não são reembolsáveis em qualquer circunstância de acordo com os termos e condições gerais da SLIDE IN.'
                      data-pr-position='bottom'
                      data-pr-event='hover'
                    />
                  </div>
                }
                body={(pag: Transacao) => {
                  return pag.custo_operacao.toString() + '€';
                }}
                style={{ width: '110px' }}
              />
              <Column
                header={'Estado'}
                body={(pag: Transacao) => {
                  return pag.estado;
                }}
              />
            </DataTable>

            {viagem.referencias_outros?.length > 0 && (
              <>
                <Divider></Divider>
                <p className={'text-xl mb-1 font-bold'}>Referência Multibanco Extra</p>
                {viagem.referencias_outros.map((ref) => {
                  return (
                    <>
                      <Divider></Divider>
                      <div
                        key={ref.referencia}
                        className='flex flex-row gap-p-2 ml-4 gap-5 '>
                        <div className={'w-4rem'}>
                          <MBLOGO />
                        </div>
                        <div className={'flex flex-column gap-1 w-30rem'}>
                          <div className={'flex flex-row '}>
                            <b>Entidade: </b>
                            <span className={'ml-1'}>
                              {ref?.entidade}
                              <i
                                className={PrimeIcons.COPY + ' mx-2'}
                                onClick={() => {
                                  navigator.clipboard.writeText(ref?.entidade).then(() => {
                                    msgSuccess('Referência Multibanco', 'Entidade copiada!');
                                  });
                                }}></i>
                            </span>
                          </div>
                          <div className={'flex flex-row'}>
                            <b>Referência:</b>
                            <div className={'monospaced font-semibold ml-1'}>
                              {ref.referencia}
                              <i
                                className={PrimeIcons.COPY + ' mx-2'}
                                onClick={() => {
                                  navigator.clipboard.writeText(ref?.referencia).then(() => {
                                    msgSuccess('Referência Multibanco', 'Referência copiada!');
                                  });
                                }}></i>
                            </div>
                          </div>
                          <div className={'flex flex-row'}>
                            <b>Valor: </b>
                            <div className={'monospaced font-semibold ml-1'}>{ref.valor}€</div>
                          </div>
                          <div className={'flex flex-row'}>
                            {/*<b>Validade: </b>*/}
                            {/*<div className={'monospaced font-semibold ml-1'}>*/}
                            {/*  {dayjs(ref.data_fim).format('YYYY-MM-DD HH:mm')}*/}
                            {/*</div>*/}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                <Divider></Divider>
              </>
            )}

            {docsGerais.length > 0 && (
              <>
                <Divider></Divider>

                <div className='flex flex-column'>
                  <p className={'text-xl mb-1 font-bold'}>Documentos Viagem</p>
                  {docsGerais.map((doc) => (
                    <Link
                      className={'m-1'}
                      target={'_blank'}
                      download
                      key={doc.titulo}
                      to={doc.file}>
                      🔗 {doc.titulo}
                    </Link>
                  ))}
                </div>
              </>
            )}

            <Divider></Divider>
            <div className='flex flex-column'>
              <p className={'text-xl mb-1 font-bold'}>
                Condições Gerais de Venda e Política de Privacidade
              </p>
              {grupoInfo.campanha.documentos_condicoes.map((doc) => (
                <Link
                  className={'m-1'}
                  target={'_blank'}
                  download
                  key={doc.titulo}
                  to={doc.file}>
                  🔗 {doc.titulo}
                </Link>
              ))}
            </div>
            {viagem.valor_pago >= viagem.valor_total / 2 && (
              <>
                <Divider></Divider>
                <p className={'text-xl mb-1 font-bold'}>Declarações:</p>
                <div className='flex flex-column'>
                  <a
                    className={'m-1 cursor-pointer'}
                    onClick={() => {
                      UtilizadorService.utilizadorComprarDecaPdfRetrieve({
                        id: parseInt(viagemID),
                      }).then((res) => {
                        const blob = new Blob([res as unknown as BlobPart], {
                          type: 'application/pdf;charset=utf-8',
                        });
                        FileSaver.saveAs(blob, `declaracao_partipacao.pdf`);
                        return res;
                      });
                    }}>
                    🔗 <span className={' my-2 mb-1 font-bold'}>Declaração de Participação</span>
                  </a>
                </div>
              </>
            )}

            {viagem.idade_no_fim < 18 && (
              <>
                <Divider></Divider>
                <p className={'text-2xl mb-1 font-bold'}>
                  Autorização de saída de menor de território nacional
                </p>
                <b>Modelo/Template - </b>
                <Link
                  target={'_blank'}
                  download
                  className={'mt-4'}
                  to={grupoInfo.campanha.minuta_menores?.file}>
                  🔗 {grupoInfo.campanha.minuta_menores?.titulo}
                </Link>
                <Divider></Divider>
                {viagem.deca_menor_file && (
                  <>
                    <p className={'text-xl mb-1 font-bold'}>Ficheiro submetido:</p>
                    <Link
                      target={'_blank'}
                      download
                      className={'mt-4'}
                      to={viagem.deca_menor_file?.file}>
                      🔗 {viagem.deca_menor_file?.file.split('/').pop()}
                    </Link>
                  </>
                )}
                <FileUpload
                  className={'my-2'}
                  mode='basic'
                  accept='application/msword, application/pdf, image/*'
                  maxFileSize={10000000}
                  auto={true}
                  customUpload
                  chooseLabel={
                    !viagem.deca_menor_file ? 'Submeter Declaração' : 'Substituir Declaração'
                  }
                  uploadHandler={upDeca}
                  onValidationFail={sizeCheck}
                  invalidFileSizeMessageDetail={'Ficheiro demasiado grande > 10 mb!'}
                />
                <i>Só é possível a submissão de um ficheiro de tamanho máximo 10mb.</i>
              </>
            )}
          </Card>
        </div>
      </div>
      <Dialog
        header={<h3>Obrigado pela preferência!</h3>}
        visible={obgComprar}
        onHide={() => {
          setObgComprar(false);
        }}>
        <h5>
          Aqui podes consultar a tua viagem e as formas de pagamento. Alguma dúvida não hesites em
          contactar-nos.
        </h5>

        <h4 className={'right-0'}>Boa Viagem!</h4>
        <Button
          className={'right-0 relative'}
          outlined
          label={'Continuar'}
          onClick={() => setObgComprar(false)}
        />
      </Dialog>
    </div>
  );
}
