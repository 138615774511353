/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelInfo } from '../models/CancelInfo';
import type { Cliente } from '../models/Cliente';
import type { ClienteRequest } from '../models/ClienteRequest';
import type { CompraActividade } from '../models/CompraActividade';
import type { CompraActividadeRequest } from '../models/CompraActividadeRequest';
import type { CompraSuplemento } from '../models/CompraSuplemento';
import type { CompraSuplementoRequest } from '../models/CompraSuplementoRequest';
import type { DocumentosCondicoesGeraisViagem } from '../models/DocumentosCondicoesGeraisViagem';
import type { DocumentosPrivacidade } from '../models/DocumentosPrivacidade';
import type { GrupoAderirInfo } from '../models/GrupoAderirInfo';
import type { PasswordResetConfirmRetype } from '../models/PasswordResetConfirmRetype';
import type { PasswordResetConfirmRetypeRequest } from '../models/PasswordResetConfirmRetypeRequest';
import type { PatchedClienteRequest } from '../models/PatchedClienteRequest';
import type { QuartoItem } from '../models/QuartoItem';
import type { RefsMultibanco } from '../models/RefsMultibanco';
import type { RefsMultibancoRequest } from '../models/RefsMultibancoRequest';
import type { SendEmailReset } from '../models/SendEmailReset';
import type { SendEmailResetRequest } from '../models/SendEmailResetRequest';
import type { ViagemCompras } from '../models/ViagemCompras';
import type { ViagemComprasRequest } from '../models/ViagemComprasRequest';
import type { ViagemDecaMenores } from '../models/ViagemDecaMenores';
import type { ViagemDecaMenoresRequest } from '../models/ViagemDecaMenoresRequest';
import type { ViagemQuarto } from '../models/ViagemQuarto';
import type { ViagemQuartoRequest } from '../models/ViagemQuartoRequest';
import type { ViagensGrupo } from '../models/ViagensGrupo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UtilizadorService {

    /**
     * @returns Cliente
     * @throws ApiError
     */
    public static utilizadorCreate({
        requestBody,
    }: {
        requestBody: ClienteRequest,
    }): CancelablePromise<Cliente> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cliente/utilizador',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Cliente
     * @throws ApiError
     */
    public static utilizadorRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
    }): CancelablePromise<Cliente> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Cliente
     * @throws ApiError
     */
    public static utilizadorUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
        requestBody: ClienteRequest,
    }): CancelablePromise<Cliente> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/cliente/utilizador/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Cliente
     * @throws ApiError
     */
    public static utilizadorPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Utilizador.
         */
        id: number,
        requestBody?: PatchedClienteRequest,
    }): CancelablePromise<Cliente> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/cliente/utilizador/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompraActividade
     * @throws ApiError
     */
    public static utilizadorAddActividadeCreate({
        requestBody,
    }: {
        requestBody: CompraActividadeRequest,
    }): CancelablePromise<CompraActividade> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cliente/utilizador/add_actividade',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static utilizadorAddActividadeDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this compra actividade.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/cliente/utilizador/add_actividade/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CompraSuplemento
     * @throws ApiError
     */
    public static utilizadorAddSuplementoCreate({
        requestBody,
    }: {
        requestBody: CompraSuplementoRequest,
    }): CancelablePromise<CompraSuplemento> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cliente/utilizador/add_suplemento',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static utilizadorAddSuplementoDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this compra suplemento.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/cliente/utilizador/add_suplemento/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CancelInfo
     * @throws ApiError
     */
    public static utilizadorCancelarRetrieve({
        id,
    }: {
        id: string,
    }): CancelablePromise<CancelInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/cancelar/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ViagensGrupo
     * @throws ApiError
     */
    public static utilizadorChefeViagensRetrieve({
        codigo,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
    }): CancelablePromise<ViagensGrupo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/chefe_viagens/{codigo}',
            path: {
                'codigo': codigo,
            },
        });
    }

    /**
     * @returns ViagensGrupo
     * @throws ApiError
     */
    public static utilizadorChefeViagensMapaQuartosRetrieve({
        codigo,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
    }): CancelablePromise<ViagensGrupo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/chefe_viagens/{codigo}/mapa_quartos',
            path: {
                'codigo': codigo,
            },
        });
    }

    /**
     * @returns ViagensGrupo
     * @throws ApiError
     */
    public static utilizadorChefeViagensMapaTransportesRetrieve({
        codigo,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
    }): CancelablePromise<ViagensGrupo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/chefe_viagens/{codigo}/mapa_transportes',
            path: {
                'codigo': codigo,
            },
        });
    }

    /**
     * @returns ViagemCompras
     * @throws ApiError
     */
    public static utilizadorComprarCreate({
        requestBody,
    }: {
        requestBody: ViagemComprasRequest,
    }): CancelablePromise<ViagemCompras> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cliente/utilizador/comprar',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ViagemCompras
     * @throws ApiError
     */
    public static utilizadorComprarRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<ViagemCompras> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/comprar/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static utilizadorComprarDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/cliente/utilizador/comprar/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ViagemQuarto
     * @throws ApiError
     */
    public static utilizadorComprarAtribuiQuartoUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
        requestBody?: ViagemQuartoRequest,
    }): CancelablePromise<ViagemQuarto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/cliente/utilizador/comprar/{id}/atribui_quarto',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ViagemCompras
     * @throws ApiError
     */
    public static utilizadorComprarCvdvRetrieve({
        iban,
        id,
        titular,
    }: {
        iban: string,
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
        titular: string,
    }): CancelablePromise<ViagemCompras> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/comprar/{id}/cvdv',
            path: {
                'id': id,
            },
            query: {
                'iban': iban,
                'titular': titular,
            },
        });
    }

    /**
     * @returns ViagemCompras
     * @throws ApiError
     */
    public static utilizadorComprarDecaPdfRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<ViagemCompras> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/comprar/{id}/deca_pdf',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns DocumentosCondicoesGeraisViagem
     * @throws ApiError
     */
    public static utilizadorCondgeraisList(): CancelablePromise<Array<DocumentosCondicoesGeraisViagem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/condgerais',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static utilizadorCondgeraisDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Documento Condições Gerais.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/cliente/utilizador/condgerais/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ViagemDecaMenores
     * @throws ApiError
     */
    public static utilizadorDecaCreate({
        formData,
    }: {
        formData: ViagemDecaMenoresRequest,
    }): CancelablePromise<ViagemDecaMenores> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cliente/utilizador/deca',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns ViagemCompras
     * @throws ApiError
     */
    public static utilizadorFechadaRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<ViagemCompras> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/fechada/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ViagemCompras
     * @throws ApiError
     */
    public static utilizadorFechadaDecaPdfRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this viagem.
         */
        id: number,
    }): CancelablePromise<ViagemCompras> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/fechada/{id}/deca_pdf',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns GrupoAderirInfo
     * @throws ApiError
     */
    public static utilizadorGrupoInfoRetrieve({
        codigo,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
    }): CancelablePromise<GrupoAderirInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/grupo_info/{codigo}',
            path: {
                'codigo': codigo,
            },
        });
    }

    /**
     * @returns GrupoAderirInfo
     * @throws ApiError
     */
    public static utilizadorGrupoInfoAderirRetrieve({
        codigo,
    }: {
        /**
         * A unique value identifying this Grupo .
         */
        codigo: string,
    }): CancelablePromise<GrupoAderirInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/grupo_info_aderir/{codigo}',
            path: {
                'codigo': codigo,
            },
        });
    }

    /**
     * @returns Cliente
     * @throws ApiError
     */
    public static utilizadorMeRetrieve(): CancelablePromise<Cliente> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/me',
        });
    }

    /**
     * @returns Cliente
     * @throws ApiError
     */
    public static utilizadorMeUpdate({
        requestBody,
    }: {
        requestBody: ClienteRequest,
    }): CancelablePromise<Cliente> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/cliente/utilizador/me',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Cliente
     * @throws ApiError
     */
    public static utilizadorMePartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedClienteRequest,
    }): CancelablePromise<Cliente> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/cliente/utilizador/me',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DocumentosPrivacidade
     * @throws ApiError
     */
    public static utilizadorPrivacidadeList(): CancelablePromise<Array<DocumentosPrivacidade>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/privacidade',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static utilizadorPrivacidadeDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Documento Privacidade.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/cliente/utilizador/privacidade/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * ListModelMixin and GenericViewSet only Viewset
     * @returns QuartoItem
     * @throws ApiError
     */
    public static utilizadorQuartosList({
        grupo,
    }: {
        grupo?: string,
    }): CancelablePromise<Array<QuartoItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cliente/utilizador/quartos',
            query: {
                'grupo': grupo,
            },
        });
    }

    /**
     * @returns RefsMultibanco
     * @throws ApiError
     */
    public static utilizadorRefmulCreate({
        requestBody,
    }: {
        requestBody: RefsMultibancoRequest,
    }): CancelablePromise<RefsMultibanco> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cliente/utilizador/refmul',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static utilizadorRefmulDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this referencia multibanco.
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/cliente/utilizador/refmul/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SendEmailReset
     * @throws ApiError
     */
    public static utilizadorResetPasswordCreate({
        requestBody,
    }: {
        requestBody: SendEmailResetRequest,
    }): CancelablePromise<SendEmailReset> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cliente/utilizador/reset_password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PasswordResetConfirmRetype
     * @throws ApiError
     */
    public static utilizadorResetPasswordConfirmCreate({
        requestBody,
    }: {
        requestBody: PasswordResetConfirmRetypeRequest,
    }): CancelablePromise<PasswordResetConfirmRetype> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cliente/utilizador/reset_password_confirm',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
